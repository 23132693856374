import React from "react"
import styled, { css } from "styled-components"

const SlideIndicator = styled.button`
  display: block;
  cursor: pointer;
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.secondary};
  background-color: ${(props) =>
    props.active === true ? props.theme.secondary : "unset"};
  margin: 0 5px;
  :focus {
    outline-color: ${(props) => props.theme.primary};
  }
`

const SliderControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
  justify-content: ${(props) =>
    props.position === "bottom-right" || props.position === "right"
      ? "flex-end"
      : "center"};
  margin-top: ${(props) => (props.position === "bottom-right" ? "-20px" : "0")};
  ${(props) =>
    props.position === "bottom-right" &&
    css`
      @media (max-width: 1195px) {
        margin-top: 20px;
        justify-content: center;
      }
    `}
`

const ArrowButton = styled.button`
  background: none;
  border: none;
  height: 40px;
  cursor: pointer;
  margin-${(props) => (props.side === "left" ? "right" : "left")}: 30px;
  position: relative;
  :focus{
    outline: none;
    ::before{
      border-color: ${(props) => props.theme.primary};
    }
  }
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-bottom: 2px ${(props) => props.theme.secondary} solid;
    border-left: 2px ${(props) => props.theme.secondary} solid;
    transform: translate(${(props) =>
      props.side === "left" ? "-20%" : "-80%"}, -50%)
      rotate(${(props) => (props.side === "left" ? "45deg" : "-135deg")});
      position: absolute;
    }
    `

const SliderControls = ({
  swiperInstance,
  activeSlideIndex,
  slides,
  position,
}) => {
  return (
    <SliderControlsWrapper position={position}>
      <ArrowButton
        type="button"
        aria-label="Show previous slide"
        side="left"
        onClick={() => swiperInstance.current.slidePrev()}
      />
      <ul style={{ display: "flex", margin: "0", alignItems: "center" }}>
        {slides.map((_slide, index) => {
          return (
            <li key={index} style={{ display: "block", margin: 0 }}>
              <SlideIndicator
                type="button"
                aria-label={`Go to slide ${index + 1}`}
                active={activeSlideIndex === index}
                onClick={() => swiperInstance.current.slideToLoop(index)}
              />
            </li>
          )
        })}
      </ul>
      <ArrowButton
        type="button"
        aria-label="Show next slide"
        onClick={() => swiperInstance.current.slideNext()}
      />
    </SliderControlsWrapper>
  )
}

export default SliderControls
